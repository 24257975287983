const axios = require("axios");
const {v4: uuidv4} = require("uuid");
const trainAPIToObject = require("./trainAPIToObject")

module.exports = async function getTrainInfo(trainNumber, date, thisone, disableForeignTrains = false, passageDate = date, departStation = {}, arriveStation = {}, trajetID = -1, itinerary = false, codeCirculation = "false") {
    let parsedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    console.log(trainNumber)

    if(trainNumber === "574.8" || trainNumber === "574,8" || trainNumber === "V150") {
        console.log("Ohh!")

        let arrets = [
            {
                "gare": "Prény",
                "heure": "13h01",
                "heureTheorique": "13h01",
                "date": "3 avril",
                "datePrecise": "3 avril 2007",
                "evenement": false,
                "voie": false,
            },
            {
                "gare": "Éclaires",
                "heure": "13h14",
                "heureTheorique": "13h14",
                "date": "3 avril",
                "datePrecise": "3 avril 2007",
                "evenement": false,
                "voie": false,
            },
            {
                "gare": "Champagne-Ardenne TGV",
                "heure": "13h31",
                "heureTheorique": "13h31",
                "date": "3 avril",
                "datePrecise": "3 avril 2007",
                "evenement": false,
                "voie": false,
            }
        ]

        let compo = [
            {
                "stop": 0,
                "element": [
                    {
                        "libelleTypeMateriel": "TGV POS",
                        "libelleFamilleMateriel": "Automoteurs TGV",
                        "libelleNature": "Commercial",
                        "numerotationVoitureTete": "8",
                        "numeroAffectation": "4402",
                        "nomLivree": "V150",
                        "nomCommercial": "POS",
                        "nombreVoituresCommerciales": "4",
                    }
                ],
                "nombreElements": 1
            }
        ]

        let currData = {
            'type': "TGV",
            'id': "V150",
            'origin': arrets[0],
            'destination': arrets[2],
            'comp': compo,
            'commercialName': false,
            'arrets': arrets,
            'duration': "0h30",
            'circulationCalendar': [{
                highlight: true,
                dates: new Date("2007-04-03T11:01:00.000Z")
            }],
            'uuid': uuidv4(),
            circulation: {}
        }

        thisone.trainData.splice(1, 0, currData);
        return
    }

    try {
    // calls the api
    axios.get(`/train?number=${trainNumber}&date=${parsedDate}`).then(element => {
        try {
            // is there any train with such a number?
            if (element.data.reponseRechercherListeCirculations.reponse.listeResultats.resultat[0].donnees.listeCirculations) {
                let circulations = element.data.reponseRechercherListeCirculations.reponse.listeResultats.resultat[0].donnees.listeCirculations.circulation
                let circulation = circulations[0]


                if(codeCirculation !== "false") {
                    if(circulations[0].codeCirculation !== codeCirculation) {
                        let index = circulations.findIndex(x => x.codeCirculation === codeCirculation);
                        circulation = circulations[index]
                    }
                }

                trainAPIToObject(element, circulation, disableForeignTrains, trainNumber, thisone, departStation, arriveStation, passageDate, itinerary, trajetID);
            } else {
                thisone.$buefy.toast.open({
                    message: 'Une erreur est survenue. Est-ce que le numéro de train est valide?',
                    type: 'is-danger'
                })
                console.log("Une erreur est survenue. Train cherché: " + trainNumber)
            }
        } catch (e) {
            console.log("c'est cassé")
            console.log(e)
            thisone.$buefy.toast.open({
                message: 'Une erreur est survenue.',
                type: 'is-danger'
            })        }
    })
    } catch (e) {
        console.log("c'est cassé")
        console.log(e)
        thisone.$buefy.toast.open({
            message: 'Une erreur est survenue.',
            type: 'is-danger'
        })
    }
}