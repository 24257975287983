<template>
  <span
    v-if="element.numerotationVoitureTete && element.numerotationVoitureQueue"
  >
    — {{ element.numerotationVoitureTete }} <b-icon
      icon="arrow-right"
      size="is-small"
    /> {{ element.numerotationVoitureQueue }}
  </span>
</template>

<script>
export default {
  name: "NumerotationNumber",
  props: {
    element: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>