<template>
  <div>
    {{ gare.gare }} <br>
    {{ gare.heure }} <br>
    {{ gare.date }}
  </div>
</template>

<script>
export default {
  name: "DepartTerminus",
  props: {
    gare: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>