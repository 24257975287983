<template>
  <span v-if="typeof numeroCourseCouplee !== undefined && numeroCourseCouplee !== numeroTrain && linkToSearch">
    (couplée au <a @click="emitSearchRequest(numeroCourseCouplee)">{{ numeroCourseCouplee }}</a>)
  </span>
  <span v-else-if="typeof numeroCourseCouplee !== undefined && numeroCourseCouplee !== numeroTrain">
    (couplée au {{ numeroCourseCouplee }})
  </span>
</template>

<script>
export default {
  name: "CourseCouplee",
  props: {
    numeroCourseCouplee: {
      type: String,
      required: true
    },
    numeroTrain: {
      type: String,
      required: true
    },
    linkToSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods:
  {
    emitSearchRequest(number) {
      this.$emit("search-request", number);
    }
  }
}
</script>

<style scoped>

</style>