const {v4: uuidv4} = require("uuid");
const getTrainModel = require("french-train-identifier");
const getCompoAsString = require("./getCompoAsString");

module.exports = function trainAPIToObject(element,
                                           circulation,
                                           disableForeignTrains = false,
                                           trainNumber = 0,
                                           thisone,
                                           departStation = {},
                                           arriveStation = {},
                                           passageDate = new Date(),
                                           itinerary = false,
                                           trajetID = -1)
{
    // always the first circulation, if it's french
//    let circulation = element.data.reponseRechercherListeCirculations.reponse.listeResultats.resultat[0].donnees.listeCirculations.circulation[0]
    console.log(circulation.mode.typeLibelle + " n°" + circulation.numero + " de " + circulation.origine.libelle + " à " + circulation.destination.libelle);

    if (disableForeignTrains === true && circulation.operateur.codeOperateur !== "1187" && /468|469/.test(trainNumber).length === 0) {
        thisone.$buefy.toast.open({
            message: 'Aucun train français n\'est disponible. Est-ce que le numéro de train est valide?',
            type: 'is-danger'
        })
        return
    }

    let circulationCalendarVCalendar = [];
    if (circulation.calendrierCirculation) {
        circulation.calendrierCirculation.forEach(trainDate => {
            let calendarObject = {
                highlight: true,
                dates: new Date(trainDate)
            }
            circulationCalendarVCalendar.push(calendarObject)
        })
    }

    let listeArretsHoraires = [];

    circulation.listeArretsDesserte.arret.forEach((currentStop) => {
        let time;
        if (currentStop.depart) {
            time = new Date(currentStop.depart.dateHeureInterne);
        } else {
            time = new Date(currentStop.arrivee.dateHeureInterne);
        }
        const displayedTime = time.getHours() + "h" + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()

        let timeTheorique = new Date(currentStop.depart ? currentStop.depart.dateHeure : currentStop.arrivee.dateHeure)

        const displayedTimeTheorique = timeTheorique.getHours() + "h" + (timeTheorique.getMinutes() < 10 ? '0' : '') + timeTheorique.getMinutes()

        const displayedDate = time.toLocaleDateString('fr-FR', {
            month: 'long',
            day: 'numeric'
        })


        let evenement;
        if (currentStop.depart) {
            evenement = currentStop.depart.evenement ? currentStop.depart.evenement : false;
        } else {
            evenement = currentStop.arrivee.evenement ? currentStop.arrivee.evenement : false;
        }

        // if the platform is already available
        const voie = currentStop.voie ? currentStop.voie.numero : false;

        // finds is there another station in place of the current one
        let desserteReport
        if (currentStop.depart && currentStop.depart.desserteReport) {
            desserteReport = currentStop.depart.desserteReport
        } else if (currentStop.arrivee && currentStop.arrivee.desserteReport) {
            desserteReport = currentStop.arrivee.desserteReport
        } else {
            desserteReport = false
        }

        let statutModification;
        if (currentStop.depart) {
            statutModification = currentStop.depart.statutModification ? currentStop.depart.statutModification : false;
        } else if (currentStop.arrivee) {
            statutModification = currentStop.arrivee.statutModification ? currentStop.arrivee.statutModification : false;
        } else {
            statutModification = false
        }

        // create the object used to store the stop's info
        const arretObject = {
            "gare": currentStop.emplacement.libelle,
            "heure": displayedTime,
            "heureTheorique": displayedTimeTheorique,
            "date": displayedDate,
            "datePrecise": time,
            "monteeOuDescenteInterdite": (currentStop.indicateurMonteeInterdite ? "NoBoarding" : false)
                ||
                (currentStop.indicateurDescenteInterdite ? "NoLeave" : false),
            "evenement": evenement,
            "voie": voie,
            "desserteReport": desserteReport,
            statutModification: statutModification
        }

        // push to the listeArretsHoraires array
        listeArretsHoraires.push(arretObject);
    })

    let currData = {}
    let commercialName = false;
    let compositions = [];
    circulation.listeArretsDesserte.arret.forEach((element) => {
        if (typeof (element.depart) !== "undefined") {
            if (typeof (element.depart.composition) !== "undefined") {
                compositions.push({
                    stop: element.rang,
                    element: element.depart.composition.element,
                    nombreElements: element.depart.composition.nombreElements
                })
            }
        }
        if (typeof (element.arrivee) !== "undefined") {
            if (typeof (element.arrivee.composition) !== "undefined") {
                compositions.push({
                    stop: element.rang,
                    element: element.arrivee.composition.element,
                    nombreElements: element.arrivee.composition.nombreElements
                })
            }
        }
    })

    let depart = listeArretsHoraires[0];
    let terminus = listeArretsHoraires[listeArretsHoraires.length - 1];

    let duration = new Date(terminus.datePrecise.getTime() - depart.datePrecise.getTime())
    let displayedDuration = duration.getUTCHours() + "h" + (duration.getUTCMinutes() < 10 ? '0' : '') + duration.getUTCMinutes()


    if (Object.keys(departStation).length > 0 && Object.keys(arriveStation).length > 0) {
        depart = departStation;
        terminus = arriveStation;
    }

    // creates an array of unique compos
    let unique = []
    compositions.forEach(item => {
        if (!(unique.filter(a => getCompoAsString(a.element) === getCompoAsString(item.element)).length > 0)) {
            unique.push(item)
        }
    })

    let commercialNames = [];

    unique.forEach(item => {
        commercialName = [];
        item.element.forEach((compoElement, index) => {
            if (compoElement.numeroAffectation) {
                commercialName[index] = getTrainModel(compoElement.numeroAffectation)
            } else if (compoElement.serieMateriel) {
                commercialName[index] = getTrainModel(compoElement.serieMateriel)
            }
        })
        commercialNames.push(commercialName)
    })

    currData = {
        'type': circulation.marque.libelle ? (circulation.mode.typeLibelle === "Train" ? circulation.marque.libelle : circulation.mode.typeLibelle) : circulation.mode.typeLibelle,
        'id': circulation.numero,
        'origin': depart,
        'passage': passageDate,
        'destination': terminus,
        'comp': unique,
        'commercialName': commercialName,
        'arrets': listeArretsHoraires,
        'duration': displayedDuration,
        'circulationCalendar': circulationCalendarVCalendar,
        'uuid': uuidv4(),
        'circulation': circulation
    }
    console.log("Itinéraire: " + itinerary)
    if (itinerary) {
        console.log("trajetID: ", trajetID)
        console.log(JSON.parse(JSON.stringify(currData)))
        thisone.trainData[trajetID].splice(1, 0, currData);
    } else {
        console.log("Pas de trajetID")
        //thisone.trainData[trajetID].splice(1, 0, currData);
        thisone.trainData.splice(1, 0, currData);
    }
    return currData;
}