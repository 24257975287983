import Vue from 'vue'
import VueRouter from 'vue-router'
import mainCompoViewer from "../views/main";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainCompoViewer',
    component: mainCompoViewer
  },
  {
    path: '/engare',
    name: 'Horaires en gare',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/enGare.vue')
  },
  {
    path: '/batch',
    name: 'Batch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/batch.vue')
  },
  {
    path: '/itinerary',
    name: 'itinerary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/itinerary.vue')
  },
  {
    path: '/rame',
    name: 'rame',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/engin.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/archive.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
