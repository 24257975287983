<template>
  <div>
    <div class="buttons">
      <b-button
        icon-right="eraser"
        type="is-danger is-light"
        size="is-medium"
        @click="deleteTrainInfo(row.uuid) "
      />

      <div v-if="row.comp.length > 0">
        <b-button

          icon-right="calendar"
          type="is-info is-light"
          size="is-medium"
          @click="isCalendarModalActive = true"
        />
        <br>
        <b-button

          icon-right="info"
          type="is-info is-light"
          size="is-medium"
          @click="isInfoModalActive = true"
        />
        <br>
        <b-button
          icon-right="copy"
          type="is-info is-light"
          size="is-medium"
          @click="copyURLWithTrainNumber(row.id)"
        />
        <b-modal v-model="isCalendarModalActive">
          <Calendar
            color="blue"
            is-range
            :attributes="row.circulationCalendar"
          />
        </b-modal>
        <br>
        <b-modal v-model="isInfoModalActive">
          <div
            class="modal-card"
            style="width: auto"
          >
            <header class="modal-card-head">
              <p class="modal-card-title">
                Info composition
              </p>
            </header>
            <section class="modal-card-body">
              <p v-if="row.circulation.statutCommercial">
                Statut commercial: {{ row.circulation.statutCommercial.libelleStatut }} ({{ row.circulation.statutCommercial.codeStatut }})
              </p>
              <p v-if="row.circulation.numeroCirculation !== row.circulation.numeroMarche">
                N° circulation: {{ row.circulation.numeroCirculation }}
                N° marche: {{ row.circulation.numeroMarche }}
              </p>

              <div
                v-for="(compo, index2) in row.comp[0].element"
                :key="'A'+index2"
              >
                <p style="font-size: 1.4em">
                  <b>{{ index2+1 }}: {{ compo.libelleFamilleMateriel === "Automoteurs TGV" ? compo.nomCommercial : compo.numeroAffectation }}
                    {{ compo.numeroAffectation !== undefined && compo.libelleFamilleMateriel === "Automoteurs TGV" ? "("+compo.numeroAffectation+")" : "" }}</b>
                </p>
                <p>Matériel: {{ compo.nomCommercial !== undefined ? compo.nomCommercial : `série ${compo.serieMateriel}` }} {{ compo.nomCommercial !== undefined ? `(série ${compo.nomCommercial})` :"" }}</p>
                <p>Livrée: {{ compo.nomLivree ? compo.nomLivree : "Pas de livrée précisée" }}</p>
                <p>Places assises: {{ compo.nombrePlacesAssises }}</p>
                <p>Nature: {{ compo.libelleNature }}</p>
                <p v-if="compo.nomCommercial && compo.nomLivree || compo.libelleFamilleMateriel === 'Locomotives' && compo.numeroAffectation">
                  Nom du fichier: {{ getFileName(compo) }}
                </p>
                <train-img
                  :compo="compo"
                  style="width: 100%"
                />
              </div>
            </section>
          </div>
        </b-modal>
      </div>

      <div v-if="row.circulation.evenement || row.circulation.listeMessagesConjoncturels || row.circulation.situation ">
        <b-button

          icon-right="exclamation-triangle"
          type="is-info is-warning"
          size="is-medium"
          @click="isEventModalActive = true;"
        />
      </div>
      <b-modal v-model="isEventModalActive">
        <div
          class="modal-card"
          style="width: auto"
        >
          <header class="modal-card-head">
            <p class="modal-card-title">
              Évènements
            </p>
          </header>
          <section class="modal-card-body">
            <div v-if="row.circulation.listeMessagesConjoncturels !== undefined">
              <div
                v-for="(event, index2) in row.circulation.listeMessagesConjoncturels.messagesConjoncturels"
                :key="index2"
              >
                <p><b>{{ event.libelleCategorie }}: {{ event.value }}</b></p>
              </div>
              <br>
            </div>

            <div
              v-for="(event, index2) in row.circulation.evenement"
              :key="index2"
            >
              <p>{{ event.libelleCategorie }}: {{ event.texte }}</p>
              <div v-if="event.listeAutresMotifs && event.listeAutresMotifs.autreMotif.length > 0">
                <p
                  v-for="(motif, index3) in event.listeAutresMotifs.autreMotif"
                  :key="index3"
                >
                  {{ motif.value }}
                </p>
              </div>
            </div>

            <div
              v-for="(event, index2) in row.circulation.situation"
              :key="index2"
            >
              <h2 class="title">
                Situation {{ index2+1 }}: {{ event.libelleSituation }}
              </h2>
              <p style="margin-bottom: 1.2em;">
                {{ new Date(event.dateHeure).toLocaleTimeString("fr-FR", {weekday: "short", year: "numeric", month: "short", day: "numeric"}) }} - {{ event.identifiantSituation }}
              </p>
            </div>
          </section>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
import TrainImg from "@/views/components/TrainImg";
import getTrainFileName from "@/tools/getTrainFileName";
import copy from 'copy-to-clipboard';

export default {
  name: "ToolsModule",
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isCalendarModalActive: false,
      isInfoModalActive: false,
      isEventModalActive: false,
    };
  },
  components: {
    TrainImg,
    Calendar
  },
  methods: {
    deleteTrainInfo(uuid) {
      this.$emit("deleteTrain", uuid);
    },
    getFileName(compo) {
      return getTrainFileName(compo);
    },
    copyURLWithTrainNumber(number) {
      const url = `${window.location.origin}/#/?train=${number}`;
      //navigator.clipboard.writeText(url);
      copy(url);
      this.$buefy.toast.open({
        message: "L'URL a été copiée dans le presse-papier",
        type: "is-info",
      });
    }
  }
}
</script>

<style scoped>

</style>