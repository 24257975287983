<template>
  <div v-if="compo.libelleFamilleMateriel === 'Matériel remorqué voitures' && /FMA|RCA/.test(compo.typeMateriel)">
    <div>
      <img
        v-for="(voiture, index) in compo.voiture"
        :key="'C'+index"
        :src="getURLVoiture(voiture)"
        :title="voiture.categorie"
      >
    </div>
  </div>
  <div v-else>
    <img
      :src="getURL(compo)"
      :alt="getURL(compo)"
      :title="getURL(compo)"
    >
  </div>
</template>

<script>
import listeModeles from "@/assets/trains/listemodeles.json";
import getTrainFileName from "@/tools/getTrainFileName";
import getVoitureFileName from "@/tools/getVoitureFileName";

export default {
  name: "TrainImg",
  props: {
    compo: {
      type: Object,
      required: true
    }
  },
  methods: {
    aaa(compo) {
      return getTrainFileName(compo);
    },
    getURLVoiture(voiture) {
      let fileName = getVoitureFileName(voiture)
      if(listeModeles.includes(fileName)) {
        return require(`../../assets/trains/${fileName}`);
      } else {
        return ""
      }
    },
    getURL(compo) {
      let fileName = getTrainFileName(compo)
      if(listeModeles.includes(fileName)) {
        return require(`../../assets/trains/${fileName}`);
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
img {
  padding-top: 0.8em;
}
</style>