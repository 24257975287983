<template>
  <div>
    <div
      v-for="(arret, index) in arrets"
      :key="index"
    >
      <div>
        <div v-if="typeof arret.evenement !== false && (arret.evenement.type === &quot;SUPPRESSION_PARTIELLE&quot; || arret.evenement.type === &quot;SUPPRESSION_DETOURNEMENT&quot;)">
          <p>
            <b-tooltip :label="eventText(arret)">
              <del>
                {{ index+1 }}: {{ arret.gare }} à {{ arret.heure }} <i>{{ arret.voie ? `(voie ${arret.voie})` : "" }}</i>
              </del>
            </b-tooltip> ❌
          </p>
        </div>
        <div v-else>
          <div v-if="typeof arret.evenement !== false && arret.evenement.libelleCategorie === &quot;RETARD&quot;">
            <p>
              <b-tooltip :label="retardTemps(arret)">
                {{ index+1 }}: {{ arret.gare }} à {{ arret.heure }} <i>{{ arret.voie ? `(voie ${arret.voie})` : "" }}</i> <b-icon
                  size="is-small"
                  icon="clock"
                  type="is-warning is-light"
                />️
              </b-tooltip>
              <b-tooltip
                v-if="arret.statutModification === &quot;CREATION_DETOURNEMENT&quot;"
                label="Arrêt supplémentaire"
              >
                <b-icon

                  size="is-small"
                  icon="info"
                  type="is-info is-light"
                />️
              </b-tooltip>
            </p>
          </div>
          <p v-else>
            {{ index+1 }}: {{ arret.gare }} à {{ arret.heure }} <i>{{ arret.voie ? `(voie ${arret.voie})` : "" }}</i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StopsList",
  props: {
    arrets: {
      type: Array,
      required: true
    }
  },
  methods: {
    // is used to display delay info in the stop tooltip
    retardTemps(arret) {
      return `Au lieu de ${arret.heureTheorique} soit un retard de ${arret.evenement.retard.dureeInterne} minutes (${arret.evenement.texte})`
    },
    eventText(arret) {
      if(arret.evenement.type === "SUPPRESSION_DETOURNEMENT") {
        if(arret.desserteReport) {
          return `Reporté à ${arret.desserteReport.libelle} (${arret.evenement.texte})`
        } else {
          return `Détournement: ${arret.evenement.texte}`
        }
      } else {
        return arret.evenement.texte
      }
    }
  }
}
</script>

<style scoped>

</style>