
module.exports = function getVoitureFileName(voiturea) {
    let voiture = JSON.parse(JSON.stringify(voiturea))

    console.log(voiture.categorie)
    if(/OSLO/.test(voiture.categorie)) {
        return "CORAIL_OUIGO.png"
    }

    let fileName = ""
    let livery = "carmillon"

    // if categorie ends in 200, remove it
    if(voiture.categorie.endsWith(" 200")) {
        voiture.categorie = voiture.categorie.replace(" 200", "")
    }

    // replace spaces with underscores
    voiture.categorie = voiture.categorie.replaceAll(" ", "_")

    console.log(voiture.categorie)

    fileName += voiture.categorie
    fileName += "_"
    fileName += livery
    fileName += ".png"
    console.log(fileName)
    return fileName
}