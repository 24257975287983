const trainsInfo = require('../../trainmodels/trains.json');

function getLocoFileName(compo) {
    let model;
    let trainLivery;
    let number;
    trainsInfo.forEach(train => {
        // don't research if we already found the livery
        if(!trainLivery) {
            let re = new RegExp(train.regex)
            if (re.test(compo.numeroAffectation)) {
                console.log(train.model)
                model = train.model
                number = compo.numeroAffectation.match(re)[0]
                           console.log(number)
                if (number) {
                    train.trains.forEach(livery => {
                        //                 console.log(livery.livery)
    //                    console.log(livery.trains)
                        let toSearch = "BB " + number
                      //  console.log(toSearch)
                        if (livery.trains.includes(toSearch)) {
                            trainLivery = livery.livery
                            console.log(livery.livery)
                            console.log("FOUND LIVERY")
                        }
                    })
                }
            }
        }
    })

    if (trainLivery) {
        return `${model}_${trainLivery}.png`.replaceAll(" ", "_")
    } else {
        return ""
    }
}

function getTTFileName(compo) {
    let model;
    let trainLivery;
    let number;
    console.log(compo.numeroAffectation)
    trainsInfo.forEach(train => {
        let re = new RegExp(train.regex)
        if (re.test(compo.numeroAffectation)) {
            console.log(train.model)
            model = train.model
            number = compo.numeroAffectation.match(re)[0]
                      console.log(number)
            if (number) {
                train.trains.forEach(livery => {
                                    console.log(livery.livery)
                    console.log(livery.trains)
                    let toSearch = "U " + number
                     console.log(toSearch)
                    if (livery.trains.includes(toSearch)) {
                        trainLivery = livery.livery
                        console.log(livery.livery)
                        console.log("FOUND LIVERY")
                    }
                })
            }
        }
    })

    if (trainLivery) {
        return `${model}_${trainLivery}.png`.replaceAll(" ", "_")
    } else {
        return ""
    }
}

// exports the function
module.exports = function getTrainFileName(compo) {
    console.log(JSON.parse(JSON.stringify(/V2N/.test(compo.serieMateriel))))

    if(compo.libelleFamilleMateriel === 'Locomotives') {
        console.log("Locomotive")
        return getLocoFileName(compo);
    } else if(/U[0-9]{5}/.test(compo.serieMateriel)) {
        console.log("TramTrain")
        return getTTFileName(compo);
    } else if(compo.libelleTypeMateriel === "ICE") {
        return "ICE.png"
    } else if(/Z2200 CFL|VT500/.test(compo.serieMateriel)) {
        return compo.serieMateriel.replaceAll(" ", "_")+".png"
    } else if(/V2N/.test(compo.serieMateriel)) {
        console.log(`${compo.serieMateriel}_${compo.typeMateriel}_${compo.voiture.length}+"C.png`)
        return `${compo.serieMateriel}_${compo.typeMateriel}_${compo.voiture.length}C.png`
    } else {
        if(compo.nomCommercial !== undefined || compo.nomLivree !== undefined || compo.nombreVoituresCommerciales !== undefined) {
            let fileName = ""

            if(compo.libelleFamilleMateriel === "Automoteurs TGV" || /Duplex|POS|Réseau/.test(compo.nomCommercial)) {
                switch(compo.nomCommercial) {
                    case "Duplex Dasye HD":
                        fileName += "duplex_hd"
                        break;
                    case "Duplex":
                    case "Néo Duplex":
                    case "Duplex Tallon":
                    case "Duplex Dasye":
                        fileName += "duplex"
                        break;
                    case "Réseau":
                        fileName += "reseau"
                        break;
                    case "Réseau Italie":
                        fileName += "reseau"
                        break;
                    case "Duplex Océane":
                        fileName += "oceane_like"
                        break;
                    case "EuroDuplex Océane":
                        fileName += "loceane"
                        break;
                    case "EuroDuplex":
                        fileName += "duplex"
                        break;
                    case "R-Duplex Tallon":
                        fileName += "rduplex"
                        break;
                    case "Atlantique":
                        fileName += "atlantique"
                        break;
                    case "POS":
                        fileName += "pos"
                        break;
                }

                fileName += "_"

                if(compo.libelleNature === "Vide voyageur") {
                    fileName += "first_to_second"
                } else {
                    if (compo.numerotationVoitureTete === "8" || compo.numerotationVoitureTete === "10" || compo.numerotationVoitureTete === "18" || compo.numerotationVoitureTete === "20") {
                        fileName += "second_to_first"
                    } else {
                        fileName += "first_to_second"
                    }
                }

                fileName += "_"

                if(compo.nomLivree === undefined) {
                    fileName += "carmillon"
                } else {
                    fileName += compo.nomLivree
                }
                fileName += "_"

                fileName += compo.nombreVoituresCommerciales+"c"

                fileName += ".png"

                return fileName
            } else {
                if(/. TER$/.test(compo.nomCommercial)) {
                    compo.nomCommercial = compo.nomCommercial.replace(" ", "")
                }

                fileName = `${compo.nomCommercial ? compo.nomCommercial.toLowerCase() : "pasDeNomCommercial"}_${compo.nomLivree ? compo.nomLivree.toLowerCase() : ""}_${compo.nombreVoituresCommerciales ? compo.nombreVoituresCommerciales : ""}c.png`;

                if (fileName === "coradialiner_greenandbluehdf_6c.png") {
                    fileName = "regiolis_greenandbluehdf_6c.png";
                }
                return fileName
            }
        } else {
            return ""
        }
    }
}