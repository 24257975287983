<template>
  <div class="hello">
    <img
      alt="Corail B10tuxh"
      title="Corail B10tuxh"
      src="../assets/corail.jpg"
      style="width: 20%; border-radius: 10%;"
    >
    <div style="padding-left: 1em; padding-right: 1em;">
      <div class="columns">
        <div class="column is-half">
          <b-field label="Numéro de train">
            <b-input
              v-model="trainNumber"
              @keyup.native.enter="getTrainInfo"
              type="number"
              min="1"
              icon="train"
              max="999999"
              pattern="\d*"
              inputmode="numeric"
              :autofocus="true"
              placeholder="Rechercher un numéro de train (1 à 6 chiffre)"
            />
          </b-field>
          <div class="columns">
            <div class="column is-centered">
              <b-field
                label="Historique"
                icon="history"
              >
                <b-select
                  placeholder="Sélectionner un train"
                  v-model="searchHistorySelected"
                >
                  <option
                    v-for="option in searchHistory"
                    :value="option"
                    :key="option.id"
                  >
                    {{ option.train }} le {{ new Date(option.searchDate).toLocaleString() }}
                  </option>
                  <option
                    :value="{clear: true}"
                    key="clear"
                  >
                    Effacer l'historique
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>


        <div class="column if-half">
          <b-field label="Select a date">
            <b-datepicker
              v-model="trainDate"
              locale="fr-FR"
              icon="calendar"
              :first-day-of-week="1"
            />
          </b-field>
          <div class="columns is-half" />
          <div class="columns is-one-third">
            <div class="buttons column">
              <p />
              <b-loading
                :is-full-page="false"
                v-model="isGetDisabled"
                :can-cancel="false"
              />
              <b-button
                type="is-primary"
                @click="getTrainInfo"
                icon-right="search"
              >
                Rechercher
              </b-button>
              <b-button
                type="is-danger"
                @click="clearTrainInfo"
                icon-right="eraser"
              >
                Clear
              </b-button>
              <div class="-align-left">
                <b-switch v-model="disableForeignTrains">
                  SNCF seulement
                </b-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- tableau des trains -->
      <b-table
        :data="trainData"
        :bordered="true"
        :striped="false"
        :narrowed="true"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="false"
      >
        <b-table-column
          field="type"
          label="Type"
          width="100"
          numeric
          v-slot="props"
        >
          {{ props.row.type }}
        </b-table-column>

        <b-table-column
          field="numero"
          label="N°"
          width="30"
          numeric
          v-slot="props"
          sortable
        >
          {{ props.row.id }}
          <br>
          {{ props.row.circulation.codeMission !== undefined ? `(${props.row.circulation.codeMission})` : "" }}
        </b-table-column>

        <b-table-column
          field="name"
          label="Départ"
          width="200"
          v-slot="props"
        >
          <depart-terminus :gare="props.row.origin" />
        </b-table-column>

        <b-table-column
          field="target"
          label="Terminus"
          width="200"
          v-slot="props"
        >
          <depart-terminus :gare="props.row.destination" />
        </b-table-column>

        <b-table-column
          field="duration"
          label="Durée"
          width="50"
          v-slot="props"
        >
          {{ props.row.duration }}
        </b-table-column>

        <b-table-column
          field="composition"
          label="Composition"
          width="35%"
          v-slot="props"
        >
          <compo-table-element
            :row="props.row"
            :show-search-link="true"
            @searchRequest="handleSearchRequest"
          />
        </b-table-column>

        <b-table-column
          field="arrets"
          label="Arrêts"
          v-slot="props"
        >
          <stops :arrets="props.row.arrets" />
        </b-table-column>

        <b-table-column
          field="circulation"
          label="Outils"
          width="70"
          v-slot="props"
        >
          <tools-module
            @deleteTrain="deleteTrainInfo"
            :row="props.row"
          />
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import DepartTerminus from "@/views/components/departTerminus";
import CompoTableElement from "@/views/components/compoTableElement";
import Stops from "@/views/components/stops";
import ToolsModule from "@/views/components/tools";
import getTrainInfo from "@/tools/getTrainInfo";

export default {
  name: 'CompoViewer',
  components: {
    ToolsModule,
    Stops,
    CompoTableElement,
    DepartTerminus
  },
  methods: {
    getTrainInfo() {
      getTrainInfo(this.trainNumber, this.trainDate, this, this.disableForeignTrains);
    },
    // simply clears the train data array
    clearTrainInfo() {
      this.trainData = [];
    },
    // deletes a train from the list using its UUID
    deleteTrainInfo(uuid) {
      try {
        // we recreate the array by filtering out the train with the given UUID
        this.trainData = this.trainData.filter(e => e.uuid !== uuid);
          this.$buefy.toast.open({
            message: 'Train supprimé avec succès',
            duration: 800,
            type: 'is-success'
          })
      } catch (e) {
          this.$buefy.toast.open({
            message: 'Something happened: '+e,
            type: 'is-error'
          })
      }
    },
    handleSearchRequest(search) {
      getTrainInfo(search.number, search.date, this, this.disableForeignTrains);
    }
  },
  data() {
    let trainDate = new Date();
    let trainNumber; // = 3852;// 7007; //17766;
    let trainData = [];
    let searchHistory = [];
    return {
      trainData,
      trainNumber,
      trainDate,
      searchHistory,
      "searchHistorySelected": {},
      "disableForeignTrains": true,
      "isGetDisabled": false,
    }
  },
  mounted() {
    if (localStorage.trainNumber) {
      this.trainNumber = localStorage.trainNumber;
    }
    if(localStorage.searchHistory) {
      this.searchHistory = JSON.parse(localStorage.searchHistory);
    }
    if(this.$route.query.train) {
      this.trainNumber = this.$route.query.train
      this.getTrainInfo(this.trainNumber);
    }
  },
  // used to automatically save the train number and the searchHistory to localStorage
  watch: {
    trainNumber(newTrainNumber) {
      localStorage.trainNumber = newTrainNumber;
    },
    searchHistory(newSearchHistory) {
      localStorage.searchHistory = JSON.stringify(newSearchHistory);
    },
    // used to change the number to the selected old one
    searchHistorySelected(newSearchHistorySelected) {
      let clear = { a: "clear" } // used to identify nothing selected

      // if the selected item is clear, we clear the search history
      if(newSearchHistorySelected.clear === true) {
        this.searchHistory = [];
        this.searchHistorySelected = clear;
        this.$buefy.toast.open({
          message: `Historique de recherche effacé`,
          type: 'is-info'
        })
      } else {
        // if the selected item is not clear
        if(newSearchHistorySelected.a !== "clear") {
          // ...we change the selected train to the selected item
          this.trainNumber = newSearchHistorySelected.train
          this.$buefy.toast.open({
            message: `Train ${this.searchHistorySelected.train} restauré`,
            type: 'is-info'
          })
          this.searchHistorySelected = clear;
        }
      }
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
