module.exports = function getCompoAsString(compo) {
    let sCompo = "";

    if(compo && compo.length > 0) {
        compo.forEach((element, index) => {
            if(element.libelleFamilleMateriel === "Automoteurs TGV") {
                sCompo += `${element.libelleTypeMateriel} ${element.numeroAffectation === undefined ? "" : `(${element.numeroAffectation})`}`
            } else if(element.libelleFamilleMateriel === "Automoteurs et Automotrices autres que TGV") {
                if(element.numeroAffectation === "") {
                    sCompo += element.libelleTypeMateriel
                } else {
                    sCompo += `${element.libelleTypeMateriel} (${element.numeroAffectation})`
                }
            } else if(element.libelleFamilleMateriel === "Locomotives") {
                if(element.voiture && element.voiture.length === 1) {
                    if(element.voiture[0].numerotation !== undefined) {
                        sCompo += `${element.serieMateriel} (${element.voiture[0].numerotation})`
                    } else if (element.numeroAffectation !== undefined) {
                        sCompo += `${element.libelleSerieMateriel} (${element.numeroAffectation})`
                    } else {
                        sCompo += element.libelleSerieMateriel
                    }
                } else {
                    sCompo += `${element.numeroAffectation} (${element.serieMateriel})`
                }
            } else if(element.libelleNature === "Vide voyageur") {
                sCompo += `HLP: ${element.numeroCourseCouplee} (${element.numeroAffectation})`
            } else if(element.libelleFamilleMateriel === "Matériel remorqué voitures") { // voitures
                sCompo += `${element.serieMateriel}`
                if(element.voiture.length > 0) {
                    sCompo += " ("
                    element.voiture.forEach((voiture, i) => {
                        sCompo += `${voiture.categorie}${voiture.numerotation ? " " + voiture.numerotation : ""}${i < element.voiture.length - 1 ? "; " : ""}`
                    })
                    sCompo += ")"
                }
            } else {
                sCompo += `${element.serieMateriel}`
            }
            if(index < compo.length-1) {
                sCompo += ";"
            }
        })
    } else {
        sCompo = "Composition non disponible."
    }

    return sCompo

}
