<template>
  <div>
    <b-select
      v-if="row.comp.length > 1"
      v-model="selectedCompoIndex"
    >
      <option
        v-for="(option, index) in row.comp"
        :value="index"
        :key="'AAA'+option.stop"
      >
        {{ option.stop+1 }}: {{ row.arrets[option.stop].gare }}
      </option>
    </b-select>
    <div v-if="row.circulation.evenement">
      <div
        v-for="(event, index2) in row.circulation.evenement"
        :key="index2"
      >
        <p v-if="event.type=== 'SUPPRESSION'">
          Train supprimé!
        </p>
      </div>
    </div>
    <div v-if="row.circulation.mode && row.circulation.mode.typeMode === &quot;ROUTIER&quot;">
      <p>{{ row.circulation.mode.typeLibelle }}</p>
    </div>
    <div v-else>
      <div v-if="row.comp[selectedCompoIndex]">
        <div
          v-for="(compo, index) in row.comp[selectedCompoIndex].element"
          :key="index"
        >
          <div v-if="compo.libelleFamilleMateriel === 'Automoteurs TGV' || testTGV(compo.nomCommercial)">
            <div v-if="compo.libelleNature === 'Vide voyageur'">
              <p>
                {{ index+1 }}: {{ compo.nomCommercial }} {{ compo.numeroAffectation ? `(${compo.numeroAffectation})` : "" }} — Vide Voyageur
                <course-couplee
                  :numero-train="row.id"
                  :numero-course-couplee="compo.numeroCourseCouplee ? compo.numeroCourseCouplee : row.id"
                  :link-to-search="showSearchLink"
                  @search-request="emitSearchRequest"
                />
              </p>
            </div>
            <p v-else>
              {{ index+1 }}: {{ compo.libelleTypeMateriel }} {{ compo.numeroAffectation ? `(${compo.numeroAffectation})` : "" }}
              <numerotation-number :element="compo" />
              <course-couplee
                :numero-train="row.id"
                :numero-course-couplee="compo.numeroCourseCouplee ? compo.numeroCourseCouplee : row.id"
                :link-to-search="showSearchLink"
                @search-request="emitSearchRequest"
              />
            </p>
          </div>
          <div v-else>
            <div v-if="compo.libelleFamilleMateriel === &quot;Locomotives&quot;">
              <div v-if="compo.voiture && compo.voiture[0].numerotation">
                <p>
                  {{ index+1 }}: {{ compo.voiture[0].numerotation }} {{ compo.serieMateriel ? `(${compo.serieMateriel})` : '' }}
                  <numerotation-number :element="compo" />
                  <course-couplee
                    :numero-train="row.id"
                    :numero-course-couplee="compo.numeroCourseCouplee ? compo.numeroCourseCouplee : row.id"
                    :link-to-search="showSearchLink"
                    @search-request="emitSearchRequest"
                  />
                </p>
              </div>
              <div v-else>
                <p v-if="compo.numeroAffectation !== undefined">
                  {{ index+1 }}: {{ compo.numeroAffectation }} {{ compo.serieMateriel ? `(${compo.serieMateriel})` : '' }}
                  <numerotation-number :element="compo" />
                  <course-couplee
                    :numero-train="row.id"
                    :numero-course-couplee="compo.numeroCourseCouplee ? compo.numeroCourseCouplee : row.id"
                    :link-to-search="showSearchLink"
                    @search-request="emitSearchRequest"
                  />
                </p>
                <p v-else>
                  {{ index+1 }}: {{ compo.serieMateriel }}
                </p>
              </div>
            </div>
            <div v-else>
              <b-tooltip
                position="is-top"
                type="is-light"
              >
                <p>
                  {{ index+1 }}: {{ compo.numeroAffectation ? compo.numeroAffectation : compo.serieMateriel }}
                  {{ row.commercialName[index] ? `(${row.commercialName[index]})` : '' }}
                  <numerotation-number :element="compo" />
                </p>
                <template #content>
                  {{ compo.nomCommercial ? `Nom commercial: ${compo.nomCommercial}` : "" }} <br>
                  {{ compo.serieMateriel ? `Série: ${compo.serieMateriel}` : "" }} <br>
                  {{ compo.libelleFamilleMateriel ? `Famille de matériel: ${compo.libelleFamilleMateriel}` : "" }} <br>
                  {{ compo.nomLivree ? `Livrée: ${compo.nomLivree}` : "" }} <br>
                  {{ compo.nombrePlacesAssises ? `${compo.nombrePlacesAssises} places assises` : "" }} <br>
                  {{ compo.libelleNature ? `Nature: ${compo.libelleNature}` : "" }}
                </template>
              </b-tooltip>
              <b-collapse
                v-if="compo.libelleFamilleMateriel !== 'Automoteurs TGV' && !testTGV(compo.nomCommercial)"
                :open="false"
                aria-id="plusInfoTrain"
              >
                <template #trigger>
                  <b-button
                    class="is-info is-light"
                    expanded
                    label="Plus"
                    type="is-primary"
                    aria-controls="plusInfoTrain"
                  />
                </template>
                <div class="notification">
                  <div class="content">
                    <p
                      v-for="(voiture, index2) in compo.voiture"
                      :key="index2"
                    >
                      Voiture n°{{ voiture.rangVoiture }}: {{ voiture.categorie }} {{ voiture.numerotation }}
                    </p>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div
          v-for="(compo, index) in row.comp[selectedCompoIndex].element"
          :key="'B'+index"
        >
          <train-img
            :compo="compo"
          />
        </div>
      </div>
      <p v-else>
        Composition non disponible
      </p>
    </div>
  </div>
</template>

<script>
import TrainImg from "@/views/components/TrainImg";
import NumerotationNumber from "@/views/components/numerotation";
import CourseCouplee from "@/views/components/courseCouplee";

export default {
  name: "CompoTableElement",
  components: {CourseCouplee, NumerotationNumber, TrainImg},
  props: {
    row: {
      type: Object,
      required: true
    },
    showSearchLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedCompoIndex: 0
    };
  },
  methods:
  {
    emitSearchRequest(number) {
      console.log(number);
      this.$emit("searchRequest", {number: number, date: this.row.origin.datePrecise});
    },
    testTGV(nomCommercial) {
      return /Duplex|POS|Réseau/.test(nomCommercial)
    }
  }
}
</script>

<style scoped>

</style>